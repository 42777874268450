import React from 'react';
import './TierList.css';

const TierList = () => {
  /* Change this if you want to change any of your "top VPN" rankings items */
  const topVpns = [
    {
      tName: ' SurfShark',
      tRating: '9.8/10',
      tUrl: 'https://surfshark.com/',
      tImg: ' https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfQpvdW_eaYGpIfdnKLoxxMZkc5xw9d8i9fw&s',
      tDescription: 'Great for streaming and court proven.',
    },
    {
      tName: ' Mullvad',
      tRating: '9.5/10',
      tUrl: 'https://mullvad.net/en',
      tImg: ' https://play-lh.googleusercontent.com/X3V8MJMnYasSOTLeFAN-EX8vKrckT2kxnPSeKRyGEU77pa-PVOjvKSvMdcOhnHX9aEg',
      tDescription: 'Perfect for P2P and general privacy.',
    },
    {
      tName: ' AirVPN',
      tRating: '9.1/10',
      tUrl: 'https://airvpn.org/',
      tImg: ' https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqCq2eDoP-ZDiWkTJlqMDipvtAtFSib7etJg&s',
      tDescription: 'Great all around VPN at a low price.',
    },
    {
      tName: ' Windscribe',
      tRating: '8.9/10',
      tUrl: 'https://windscribe.com/',
      tImg: ' https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjTtSgsH-XavfyPprsRtCoc6Nv6cUyshLjmg&s',
      tDescription: 'A do-it-all VPN service.',
    },
    {
      tName: ' PIA',
      tRating: '8.8/10',
      tUrl: 'https://www.privateinternetaccess.com/',
      tImg: ' https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT9PNXAfSfatfSSYsnb5dCcap5kx3Pl_KFGg&s',
      tDescription: 'Great UI and court proven.',
    },
  ];

  /* Change this if you want to change any of the ordering of your VPN Tier List data for your VPN Guides rankings */
  const vpnTiers = [
  {
    tier: 'S',
    vpns: [
      { name: 'ExpressVPN', url: 'https://www.expressvpn.com/' },
      { name: 'Mullvad', url: 'https://mullvad.net/en' },
      { name: 'AirVPN', url: 'https://airvpn.org/' },
      { name: 'Windscribe', url: 'https://windscribe.com/' },
      { name: 'PIA', url: 'https://www.privateinternetaccess.com/' }
    ]
  },
  {
    tier: 'A',
    vpns: [
      { name: 'PrivateVPN', url: 'https://privatevpn.com/' },
      { name: 'OVPN', url: 'https://www.ovpn.com/en' },
      { name: 'AzireVPN', url: 'https://www.azirevpn.com/' },
      { name: 'Hide.Me', url: 'https://hide.me/en/' }
    ]
  },
  {
    tier: 'B',
    vpns: [
      { name: 'VyprVPN', url: 'https://www.vyprvpn.com/' },
      { name: 'VPNArea', url: 'https://www.vpnarea.com/' },
      { name: 'CyberGhost', url: 'https://www.cyberghostvpn.com/en_US/' },
      { name: 'Perfect-Privacy', url: 'https://www.perfect-privacy.com/' },
      { name: 'TunnelBear', url: 'https://www.tunnelbear.com/' },
      { name: 'PersonalVPN', url: 'https://personalvpn.com/' },
      { name: 'BulletVPN', url: 'https://www.bulletvpn.com/' },
      { name: 'AstrillVPN', url: 'https://www.astrill.com/' }
    ]
  },
  {
    tier: 'C',
    vpns: [
      { name: 'NordVPN', url: 'https://nordvpn.com/' },
      { name: 'SurfShark', url: 'https://surfshark.com/' },
      { name: 'TorGuard', url: 'https://torguard.net/' },
      { name: 'VPN.ac', url: 'https://vpn.ac/' },
      { name: 'CactusVPN', url: 'https://www.cactusvpn.com/' },
      { name: 'StrongVPN', url: 'https://strongvpn.com/' },
      { name: 'AdGuard', url: 'https://adguard-vpn.com/en/welcome.html' },
      { name: 'VPN Unlimited', url: 'https://www.vpnunlimited.com/' },
      { name: 'Hotspot Shield', url: 'https://www.hotspotshield.com/' },
      { name: 'ZoogVPN', url: 'https://zoogvpn.com/' }
    ]
  },
  {
    tier: 'F',
    vpns: [
      { name: 'BraveVPN', url: 'https://brave.com/firewall-vpn/' },
      { name: 'AvastVPN', url: 'https://www.avast.com/en-us/secureline-vpn#pc' },
      { name: 'IvacyVPN', url: 'https://www.ivacy.com/' },
      { name: 'HideMyAss', url: 'https://www.hidemyass.com/en-us' },
      { name: 'HideIPVPN', url: 'https://www.hideipvpn.com/' },
      { name: 'FastestVPN', url: 'https://fastestvpn.com/' },
      { name: 'HolaVPN', url: 'https://hola.org/' },
      { name: 'IPVanish', url: 'https://www.ipvanish.com/' },
      { name: 'PureVPN', url: 'https://www.purevpn.com/' }
    ]
  }
];

  return (
    <div className="tier-list">
      <img alt="VPN Guides Banner" className="logo-banner" src="https://i.e-z.host/iiuvfmip.png"></img>
        <div className="top-container">
        <div className="titles-container">
        <h3 className="main-heading">
          Why use VPN Guides?
        </h3>
        <p>
        We are 100% honest and upfront about everything we do. Every VPN we rank and list is a VPN that has been tried and tested! We are also fully open to feedback, so feel free 
        to give any feedback you may have, good or bad.
        </p>
      </div>
      </div>
      <h3>Our top VPNs:</h3>
      {topVpns.map((topVpns, index) => (
      <div className="top-vpn-container" key={index}>
        <h3>
          {index + 1})
          <a href={topVpns.tUrl} target="_blank" rel="noreferrer">
            <img alt={topVpns.tName} className="top-vpn-images" src={topVpns.tImg} />
            {topVpns.tName}
          </a>
        </h3>
        <p className="left-align">{topVpns.tDescription}</p>
        <p className="right-align">Rating: {topVpns.tRating}</p>
      </div>
    ))}
      <div className="tierlist-container">
        <div className="titles-container">
          <h3>VPN Guides 2024 v0.1</h3>
          <p>Our first launch of the VPN Guides tier list is now here</p>
        </div>
        <div className="tiers-container">
          {vpnTiers.map((tierData, index) => (
            <div className="tier" key={index}>
              <h2>{tierData.tier}</h2>
              <div className="items">
                {tierData.vpns.map((tierData, vpnIndex) => (
                  <div className="vpn-item" key={vpnIndex}>
                    <a href={tierData.url} target="_blank" rel="noopener noreferrer" className="vpn-link">
                      <h3>{tierData.name}</h3>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="updates-container">
        <h3>VPN Guides Updates - 8/2024:</h3>
          <p>Initial release of the VPN Guides rankings!</p>
      </div>
    </div>
  );
};

export default TierList;
