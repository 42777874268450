import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-title">
        <Link to="/">VPN Guides</Link>
      </div>
      <div className="header-links">
        <Link to="/">Home</Link>
        <div className="header-dropdown">
          Credit
          <div className="header-dropdown-content">
          <Link to="https://tylerthedev.pages.dev/" target='_none'>
              Tyler The Dev
            </Link>
            <Link to="https://satyrnvpntierlist.pages.dev/" target='_none'>
              Satyrn VPN Tier List
            </Link>
          </div>
        </div>
        <div className="header-dropdown">
          Policies
          <div className="header-dropdown-content">
            <a href="/privacy-policy">
              Privacy Policy
            </a>
          </div>
        </div>
        </div>
    </header>
  );
};

export default Header;
