import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-title'>
            <div className="privacy-policy-title-container">
            <h1>Privacy Policy</h1>
            </div>
        <div className="privacy-policy">
        <p>
                Last updated: 8/06/2024 @14:40 EDT (GMT-4 hours)
            </p>
            <p>
                This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information that we receive from our website users. We are 
                committed to using your personal information solely to enhance and improve our site. By accessing and using our site, you consent to collecting and utilizing 
                information by this policy.
            </p>
            <h2>Information We Collect</h2>
            <h3><b><i>Data Logs</i></b></h3> <br/>
                <p> 
                    Like many website operators, we collect information your browser automatically sends whenever you visit our site. This information, referred to as 
                    Log Data is used solely for analytical purposes. Log Data may include: <b>Visitor IP Addresses</b>, <b>Request Details</b> - This includes information about the 
                    type of requests made, such as the URLs visited, request methods (GET, POST, etc.), and the user-agent strings (which include browser and operating system 
                    details). <b>Traffic and Performance Data</b> - Cloudflare collects data to analyze traffic patterns and site performance. This includes metrics on latency, 
                    bandwidth usage, and other performance indicators. <b>Error Logs</b> - Information about errors encountered while serving pages, which helps in diagnosing issues 
                    and improving site performance.
                </p>
                <p>
                    Cloudflare’s logging practices are designed with privacy and security in mind. They anonymize IP addresses and provide options to manage and access your own data. 
                    If you need detailed information about how your data is handled, you might want to check Cloudflare’s privacy policy and terms of service or consult their 
                    support team.
                </p>
            <h3><i>Affiliate Disclosure</i></h3>
            <p>
                Please be aware that our website does <b>NOT</b> contain affiliate links. When you click on the links on the website to make a purchase, we earn <b>ZERO</b> Commission. 
                However, it is important to note that our reviews are not paid for in advance, as we pay to use the services we review, and every VPN review provided 
                is unbiased and objective (as I can humanly make it).
            </p>
            <h3><i>Changes to This Policy</i></h3>
            <p>
                We may update our Privacy Policy from time to time. We may not notify you of any changes regarding the new Privacy Policy on this page. It is advisable to review this 
                Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page: 
                Refer to the "Last Updated" section above for information.
            </p>
        </div>
        </div>
    );
}

export default PrivacyPolicy;